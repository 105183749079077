.markdown {
  a {
    --tw-text-opacity: 1;
    color: rgba(59, 130, 246, var(--tw-text-opacity));
  }

  strong {
    color: #111827;
  }

  a:hover {
    opacity: 0.9;
  }

  td {
    border: 1px solid black;
    padding: 4px;
  }


  ul {
    list-style-type: disc;
    padding: 0 0 0 40px;
  }

  ol {
    list-style-type: decimal;
    padding: 0 0 0 40px;
  }

  ul ul, ol ul {
    list-style-type: circle;;
    margin-left: 15px;
    padding: 0 0 0 40px;
  }

  ol ol, ul ol {
    list-style-type: lower-latin;
    margin-left: 15px;
    padding: 0 0 0 40px;
  }

  li {
    margin-bottom: 15px;
  }

  blockquote {
    background: #f9f9f9;
    border-left: 10px solid #ccc;
    margin: 1.5em 10px;
    padding: 0.5em 10px;
    quotes: "\201C""\201D""\2018""\2019";
  }

  blockquote:before {
    color: #ccc;
    content: open-quote;
    font-size: 4em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }

  blockquote p {
    display: inline;
  }

  figure {
    table {

    }
  }


  td {
    width: 10rem;
  }


}
